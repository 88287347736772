




































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Ref, Vue } from 'vue-property-decorator'
import { CreateCommunityViewModel } from '../../viewmodels/create-community-viewmodel'
import timezones from '@/constants/timezones.json'

@Observer
@Component({
  components: {},
})
export default class BasicInformation extends Vue {
  @Inject({}) vm!: CreateCommunityViewModel
  @Ref('profile-image-input') profileImageInput: any
  timezones = timezones

  onClickProfileImageInput() {
    this.profileImageInput?.click()
  }

  fileInputChange(event) {
    const files = event.target.files || []
    if (files.length) this.vm.changeProfileImageInput(files[0])
  }
}
